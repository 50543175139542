import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/pro-regular-svg-icons';

import config from '../config';
import useApi from '../hooks/use-api';
import Currency from './Currency';

const Card = styled.div`
    text-align: center;
    color: ${config.colors.white};
    margin-top: 60px;
    background: ${config.colors.tanLight};
`;

const Headshot = styled.img`
    display: block;
    max-width: 100%;
    margin: 0 auto -60px;
    transform: translateY(-60px);
`;

const Stats = styled.div`
    display: flex;
`;

const Stat = styled.div`
    background: ${props => props.background};
    width: 50%;
    padding: 8px 12px;
`;

const StatValue = styled.p`
    font-weight: 800;
    font-size: 24px;
    margin: 0;
    padding: 0;
    line-height: 1.4;
`;

const StatLabel = styled.p`
    font-size: 12px;
    margin: 0;
    padding: 0;
    line-height: 1.4;
    text-transform: capitalize;
`;

const Link = styled.a`
    display: block;
    background: ${config.colors.blue};
    color: ${config.colors.white};
    padding: 12px;

    &:hover {
        color: ${config.colors.white};
        text-decoration: none;
    }
`;

const Name = styled.span`
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.05;
`;

const Icon = styled.span`
    color: ${config.colors.tan};
    margin-left: 8px;
`;

const Player = ({ name, image, campaign }) => {
    const { data } = useApi(`/widgets/campaigns/${campaign}`);

    return (
        <Card>
            <Headshot src={image} alt={name} />
            {data && (
                <Stats>
                    <Stat background={config.colors.redDark}>
                        <StatValue>
                            <Currency amount={data.amountPerMetric} />
                        </StatValue>
                        <StatLabel>Raised per {data.performanceMetric.singular}</StatLabel>
                    </Stat>
                    <Stat background={config.colors.red}>
                        <StatValue>
                            <Currency amount={data.amountRaised} showCents={false} />
                        </StatValue>
                        <StatLabel>Total Raised</StatLabel>
                    </Stat>
                </Stats>
            )}
            <Link
                href={`https://pledgeit.org/${campaign}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Name>{name}</Name>
                <Icon>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} size="lg" />
                </Icon>
            </Link>
        </Card>
    );
};

Player.propTypes = {
    campaign: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default Player;
