export default {
    colors: {
        blue: '#1D3243',
        red: '#C61D3E',
        redDark: '#A01630',
        tanLighter: '#F4F1EC',
        tanLight: '#CDC4BB',
        tan: '#C2AC73',
        white: '#FBFDFF',
    },
    screens: {
        md: 'min-width: 768px',
        lg: 'min-width: 1024px',
    },

    players: [
        {
            name: 'Cookie Carrasco',
            image: '/wp-content/uploads/2019/08/Cookie-Carrasco-Headshot.png',
            campaign: 'cookie',
        },
        {
            name: 'Francisco Lindor',
            image: '/wp-content/uploads/2019/08/player-prof-francisco-lindor.png',
            campaign: 'francisco-lindor',
        },
        {
            name: 'Curtis Granderson',
            image: '/wp-content/uploads/2019/08/player-prof-curtis-granderson.png',
            campaign: 'curtis-granderson',
        },
        {
            name: 'Carlos Santana',
            image: '/wp-content/uploads/2019/08/player-prof-carlos-santana.png',
            campaign: 'carlos-santana',
        },
        {
            name: 'Franmil Reyes',
            image: '/wp-content/uploads/2019/08/player-prof-franmil-reyes.png',
            campaign: 'franmil-reyes',
        },
        {
            name: 'Shane Bieber',
            image: '/wp-content/uploads/2019/08/Shane-Bieber-Headshot.png',
            campaign: 'shane-bieber',
        },
        {
            name: 'Jesus Aguilar',
            image: '/wp-content/uploads/2019/08/player-prof-jesus-aguilar.png',
            campaign: 'jesus-aguilar',
        },
    ],
};
