import { useReducer, useEffect } from 'react';
import axios, { CancelToken } from 'axios';

const reducer = (state, action) => {
    switch (action.type) {
        case 'request':
            return {
                ...state,
                loading: true,
                error: null,
            };

        case 'response':
            return {
                loading: false,
                error: null,
                data: action.payload,
            };

        case 'error':
            return {
                loading: false,
                error: action.payload,
                data: null,
            };

        default:
            throw new Error(`No handler for action: "${action.type}`);
    }
};

export default function useApi(url) {
    const [state, dispatch] = useReducer(reducer, {
        loading: false,
        data: null,
        error: null,
    });

    useEffect(() => {
        let cancel;

        axios
            .get(`https://pledgeit.org/api-public${url}`, {
                cancelToken: new CancelToken(c => {
                    cancel = c;
                }),
            })
            .then(response => dispatch({ type: 'response', payload: response.data }))
            .catch(err => {
                const error = err.response.data;
                console.error('API Error:', error);
                dispatch({ type: 'error', payload: error.message || error });
            });

        return cancel;
    }, []);

    return state;
}
