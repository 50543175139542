import PropTypes from 'prop-types';
import numeral from 'numeral';

const Currency = ({ amount, showCents }) => {
    let formatter = '$0,0';

    if (showCents === 'optional') {
        formatter += '[.]00';
    } else if (showCents === true) {
        formatter += '.00';
    }

    return numeral(amount).format(formatter);
};

Currency.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showCents: PropTypes.oneOf([true, false, 'optional']),
};

Currency.defaultProps = {
    amount: 0,
    showCents: 'optional',
};

export default Currency;
