import React from 'react';
import styled from '@emotion/styled';

import config from './config';
import useApi from './hooks/use-api';
import Player from './components/Player';
import Currency from './components/Currency';

const Container = styled.div`
    width: 100vw;
    margin: 0 -15px;
`;

const Lineup = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    padding: 64px 5vw;
    background: ${config.colors.tanLighter};
`;

const Headline = styled.h2`
    text-align: center;
    text-transform: uppercase;
    color: ${config.colors.tan};
    padding-bottom: 24px;
`;

const PlayerList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -48px;
    margin-top: -48px;
`;

const PlayerItem = styled.div`
    padding-left: 48px;
    padding-top: 48px;
    width: 100%;
    max-width: 400px;

    @media (${config.screens.md}) {
        width: 50%;
        max-width: auto;
    }

    @media (${config.screens.lg}) {
        width: 33.333%;
        max-width: auto;
    }
`;

const Stats = styled.div`
    display: flex;
    flex-direction: column;

    @media (${config.screens.md}) {
        flex-direction: row;
    }
`;

const Stat = styled.div`
    text-align: center;
    padding: 24px 24px 36px 24px;
    color: ${config.colors.white};
    width: 100%;
    background: ${props => props.background};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (${config.screens.md}) {
        padding-top: 36px;
        padding-bottom: 48px;
        width: 50%;
    }
`;

const StatValue = styled.p`
    font-weight: 800;
    font-size: 48px;
    line-height: 1.4;
    margin: 0;
    padding: 0;
`;

const StatLabel = styled.p`
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1.4;
    margin: 0;
    padding: 0;
`;

const App = () => {
    const { data } = useApi('/widgets/templates/cookie');
    return (
        <Container>
            <Lineup>
                <Headline>Cookie&apos;s Lineup</Headline>
                <PlayerList>
                    {config.players.map(player => (
                        <PlayerItem key={player.campaign}>
                            <Player {...player} />
                        </PlayerItem>
                    ))}
                </PlayerList>
            </Lineup>
            <Stats>
                <Stat background={config.colors.tan}>
                    <StatValue>1 in 285</StatValue>
                    <StatLabel>Children are diagnosed with childhood cancer</StatLabel>
                </Stat>
                <Stat background={config.colors.blue}>
                    <StatValue>
                        {data ? <Currency amount={data.amountRaised} showCents={false} /> : '-'}
                    </StatValue>
                    <StatLabel>Total raised for St. Jude</StatLabel>
                </Stat>
            </Stats>
        </Container>
    );
};

export default App;
