import React from 'react';
import { render } from 'react-dom';

import App from './App';

const onPageLoad = fn => {
    // Page has already loaded
    if (document.readyState !== 'loading') {
        fn();
        return;
    }

    // Page is still loading
    document.addEventListener('DOMContentLoaded', fn);
};

onPageLoad(() => {
    const container = document.getElementById('dynamic');
    window.container = container;

    if (container) render(<App />, container);
});
